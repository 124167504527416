<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1000px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-form
        ref="form"
        @submit.prevent="submit"
      >
        <div class="d-flex justify-space-between py-3 px-5">
          <h3>
            Прикрепить платежное поручение
          </h3>
          <v-btn
            color="error"
            small
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <v-col class="white py-10">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="payments"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  placeholder="Прикрепите платежное поручение"
                  clearable
                  multiple
                  :accept="acceptedFormats"
                  :rules="rules.payments"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-col>


          <div class="d-flex justify-end">
            <v-btn
              dark
              color="#5CB7B1"
              class="mx-5 mt-5"

              @click="submit"
            >
              Сохранить
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";
import { mapGetters } from 'vuex';
import { deleteNulls } from "@/helpers/inputs";
export default {
  mixins:[modalWindowMixin],
  props:{
    id:{
      type:Number,
      required:true,
    }
  },
  data:() => ({
    acceptedFormats: '.png, .jpg, .jpeg, .pdf, .doc, .docx, .webp, .docx, .xml',

    payments:[],
    rules: {
      payments:[
        v => !!v.length || 'Поле является обязательным'
      ],

    },
  }),
  computed:{
    ...mapGetters({
      user:"auth/getUser"
    })
  },
  watch:{
    show(nv){
      if(nv === false){
        this.unset()
      }
    }
  },
  methods:{
    deleteNulls,
    unset(){
      this.payments = []
    },
    submit(){
      if(this.id < 0) return false;
      if(this.$refs.form.validate()){
        const formData = new FormData()

        this.payments.forEach((payments) => {
          formData.append(`payments`, payments)
        })

        this.$store.dispatch('documents/attachPaymentDocuments',{formData, id:this.id}).then(() => {
          this.$snackbar({text:"Успешно",color:"green", top:false, right: false});
          this.$store.dispatch('journal/fetchItems')
          this.close()
        }).catch(() => {
          this.$snackbar({text:"Ошибка",color:"red", top:false, right: false});
        })
      }

    }
  }


}
</script>
